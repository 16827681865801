import React, { useState } from "react";
import "./styles.css";

const Banner2 = () => {
  return (
    <div>
      <div className="mainText">
        
          <h2>
            ¿POR QUÉ COOL SUMMER?
          </h2>
          <p>
            Desde hace más de 20 años que construimos, remodelamos y modernizamos piletas en zona Norte,
            dedicando especial atención a cada detalle y manteniendo una
            relación precio calidad de primer nivel en el mercado.
          </p>
          <p>
            Buscamos la perfección en nuestros trabajos a fin de poder entregar
            a cada cliente, la pileta que proyecto en su imaginación,
            manteniendo un dialogo amistoso a fin de interpretar cada una de las
            necesidades y conformando de ese modo un excelente espacio de
            trabajo, una agradable relación y brindando un buen servicio post
            venta.
          </p>
          <p>
            Nuestra visión es la de ver disfrutar a cada familia de un espacio
            de relax único, para cada necesidad, a fin de que puedan vivir
            momentos únicos y refrescantes.
          </p>
        
      </div>
    </div>
  );
};

export default Banner2;
